import * as React from 'react'
import Layout from '../../components/Layout/Layout'
import Banner from '../../components/Banner/Banner'
import Button from '../../components/Button/Button'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import { Helmet } from 'react-helmet'

function KneePain() {
    return (
        <Layout>
            <Helmet>
                <title>Knee Pain - Conditions we treat | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>Knee Pain</h1>
            </Title>
            <section>
                <Text>
                    <p>The knee is the largest joint in the body. It is a major weight-bearing joint and is one of the most frequently injured joints in the human body.</p>
                    <p>Knee pain can have a number of different causes, can be painful and debilitating and, although some conditions may require surgery, many can be helped with the right advice, exercise and treatment. The knee joint lies between the femur and tibia and at the front is the patella or kneecap. It is made up of a number of structures including ligaments, muscles, capsule, synovial membrane and two ‘c’ shaped pieces of cartilage that sit between the femur and tibia, known as the menisci.</p>
                    <p>Damage, strain or sprain to the structures of the knee can give rise to symptoms. It can be the result of a sudden injury as often seen in sports injuries or by repeatedly placing strain on an area of the knee. Poor alignment of the knee or kneecap and altered joint mechanics in relation to other joints, such as the hips and knees, are often significant. Osteoarthritis or wear and tear is a common condition that affects the knee.</p>
                    <p>Common symptoms in the knee include pain, stiffness, aching, locking, swelling, limping and difficulty fully straightening or bending the knee.</p>
                    <p>X-rays, scans and other tests are sometimes required to make a diagnosis and your osteopath may make a referral to a GP or a specialist for any additional investigations or treatment.</p>
                </Text>
            </section>
            <section style={{backgroundColor: 'var(--primary-800)', color: 'white'}}>
                <Banner>
                    <h2>Make an appointment with us.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Banner>
            </section>
        </Layout>
    )
}

export default KneePain